<template>
  <div id="loading" class="loading">
    <div class="loader"></div>
  </div>
  <div id="dataResponseActionTable">

  </div>
</template>
<script>
//AWS - GRAPHQL
import {
} from "../graphql/queries";
import { onNotify, onUpdateSmartRecord } from '../graphql/subscriptions'
//Tools
import Vuex from "vuex";
//Components
import PaperDisplayer from "../components/papers/PaperDisplayer.vue";

export default {
  components: {
  },
  data() {
    return {
      data: ''
    };
  },
  created() {
    console.log(this.responseActionTable);
  },
  mounted() {
    document.getElementById('dataResponseActionTable').innerHTML = this.responseActionTable.value.replace(/body/g,'div')
  },
  methods: {

  },
  computed: {
    ...Vuex.mapState(["responseActionTable"]),
  },
  watch: {
    
  },
};

</script>